import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ImageFadeIn from "react-image-fade-in";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";

import envelope from '../../static/images/envelope.webp'
import VideoSkelet from "../components/videoSkelet";
import SvatbySkelet from "../components/fotoSvatbySkelet";
import { Link } from "gatsby";
import Layout from "../components/layout";

import PosterSm from "../../static/images/svatby_poster_sm.webp"
import Poster from "../../static/images/svatby_poster.webp"

const SvatbyEl = (props) => {




    const photoList = [
        {
            id: 1,
            style: "col-4",
            preview: "svatby/n/1.webp",
            full: "svatby/f/1.jpg",
            alt: "porfolio fotka svateb | svatební kameraman",
        },
        {
            id: 2,
            style: "col-4",
            preview: "svatby/n/2.webp",
            full: "svatby/f/2.jpg",
            alt: "porfolio fotka svateb | svatební kameraman Praha",
        },
        {
            id: 3,
            style: "col-4",
            preview: "svatby/n/3.webp",
            full: "svatby/f/3.jpg",
            alt: "porfolio fotka svateb | svatební fotograf Praha",
        },
        {
            id: 4,
            style: "col-12",
            preview: "svatby/n/4.webp",
            full: "svatby/f/4.jpg",
            alt: "porfolio fotka svateb | svatební kameraman Hradec Králové",
        },
        {
            id: 5,
            style: "col-12",
            preview: "svatby/n/5.webp",
            full: "svatby/f/5.jpg",
            alt: "Svatba Krkonoše",
        },
        {
            id: 6,
            style: "col-12",
            preview: "svatby/n/6.webp",
            full: "svatby/f/6.jpg",
            alt: "Svatby Nový Bydžov",
        },
        {
            id: 7,
            style: "col-12",
            preview: "svatby/n/7.webp",
            full: "svatby/f/7.jpg",
            alt: "Svatební kameraman Jičín",
        },
        {
            id: 8,
            style: "col-12",
            preview: "svatby/n/8.webp",
            full: "svatby/f/8.jpg",
            alt: "Fotografie a video Východočeský kraj",
        },
        {
            id: 9,
            style: "col-12",
            preview: "svatby/n/9.webp",
            full: "svatby/f/9.jpg",
            alt: "nejlepší svatební kamaraman",
        },
        {
            id: 10,
            style: "col-12",
            preview: "svatby/n/10.webp",
            full: "svatby/f/10.jpg",
            alt: "foto svatba",
        },
        {
            id: 11,
            style: "col-12",
            preview: "svatby/n/11.webp",
            full: "svatby/f/11.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 12,
            style: "col-12",
            preview: "svatby/n/12.webp",
            full: "svatby/f/12.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 13,
            style: "col-12",
            preview: "svatby/n/13.webp",
            full: "svatby/f/13.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 14,
            style: "col-12",
            preview: "svatby/n/14.webp",
            full: "svatby/f/14.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 15,
            style: "col-4",
            preview: "svatby/n/15.webp",
            full: "svatby/f/15.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 16,
            style: "col-4",
            preview: "svatby/n/16.webp",
            full: "svatby/f/16.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 17,
            style: "col-4",
            preview: "svatby/n/17.webp",
            full: "svatby/f/17.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 18,
            style: "col-12",
            preview: "svatby/n/18.webp",
            full: "svatby/f/18.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 19,
            style: "col-12",
            preview: "svatby/n/19.webp",
            full: "svatby/f/19.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 20,
            style: "col-12",
            preview: "svatby/n/20.webp",
            full: "svatby/f/20.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 21,
            style: "col-12",
            preview: "svatby/n/21.webp",
            full: "svatby/f/21.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 22,
            style: "col-12",
            preview: "svatby/n/22.webp",
            full: "svatby/f/22.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 23,
            style: "col-12",
            preview: "svatby/n/23.webp",
            full: "svatby/f/23.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 24,
            style: "col-12",
            preview: "svatby/n/24.webp",
            full: "svatby/f/24.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 25,
            style: "col-12",
            preview: "svatby/n/25.webp",
            full: "svatby/f/25.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 26,
            style: "col-4",
            preview: "svatby/n/26.webp",
            full: "svatby/f/26.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 27,
            style: "col-4",
            preview: "svatby/n/27.webp",
            full: "svatby/f/27.jpg",
            alt: "porfolio fotka svateb",
        },
        {
            id: 28,
            style: "col-4",
            preview: "svatby/n/28.webp",
            full: "svatby/f/28.jpg",
            alt: "porfolio fotka svateb",
        },
    ];


    const videos = [
        {
            id: 1,
            style: "col-12 col-lg-6",
            preview: "svatby/video2.webp",
            full: "https://www.youtube.com/watch?v=4TYdU-sviPE",
            alt: "Svatební video Petr & Lenka | Nové místo | Slatiňany",
            desc: "Svatební video Petr & Lenka | Nové místo | Slatiňany",
        },
        {
            id: 2,
            style: "col-12 col-lg-6",
            preview: "svatby/video1.webp",
            full: "https://www.youtube.com/watch?v=JIz0qUDNnN0",
            alt: "Svatební video Honza & Eliška | Svatby od srdce | Lhota pod Libčany",
            desc: "Svatební video Honza & Eliška | Svatby od srdce | Lhota pod Libčany",
        }
    ];




    const helmetElement = (
        <Helmet>
            <title>Svatby | Martin Petrovický | Foto a video</title>
            <meta
                name="description"
                content="Ukázka svatebních fotografií a videí. Vyberte si ze 3 balíčků nebo individuální plán."
            />
            <meta
                name="keywords"
                content="Svatba, fotograf, kameraman, svatební video, svatební fotograf, svatební kameraman"
            />
            <meta name="author" content="Martin Petrovický" />
        </Helmet>
    );


    const togglePack = (packId) => {
        var paragraph = document.querySelector(`.pp${packId}`);
        paragraph.classList.toggle("expand-pack");
        var icon = document.querySelector(`.icon${packId}`);
        icon.classList.toggle("icon-expand");
    };

    const handleImageClick = () => {
        document
            .getElementById("svatby_header")
            .scrollIntoView({ behavior: "smooth", block: "start" });
    };

    useEffect(() => {
        const path = window.location.pathname
        if (path.includes('Video')) {
            scroolToElement('wedding_video')
        }
    }, [])

    const scroolToElement = (id) => {
        const violation = document.getElementById(id);
        // const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (violation !== null) {
            setTimeout(() => {
                violation.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 200)

        }
    }

    return (
        <Layout>
            <main>
                {helmetElement}
                <div className="div-svatby-poster">
                    <div className="rounded svatby-poster-banner text-center text-white py-5">
                        <Fade delay={200} duration={1500}>
                            <h3 className="mx-2 mx-lg-5 mb-3">
                                VYTVOŘÍM VÁM SVATEBNÍ FOTOGRAFIE Z VAŠEHO SVATEBNÍHO DNE
                            </h3>
                        </Fade>
                        <Fade delay={500} duration={1500}>
                            <p>"JEDNA FOTKA VYDÁ ZA TISÍC SLOV"</p>
                        </Fade>
                    </div>
                    <div className="scroll-arrow d-block d-md-none" onClick={handleImageClick}>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                    </div>

                    <ImageFadeIn
                        opacityTransition={1.5}
                        src={Poster}
                        className="svatby-poster-img d-none d-lg-block"
                        alt="Uvodní foto na pozadí svateb"
                    />
                    <ImageFadeIn
                        opacityTransition={1.5}
                        src={PosterSm}
                        className="svatby-poster-img d-block d-lg-none"
                        alt="Uvodní foto na pozadí svateb"
                    />
                </div>
                <div id="svatby_header"></div>
                <div className="container">
                    <div className="text-center h1 mt-5 mb-4 ">Svatební Foto a Video</div>

                    <SvatbySkelet photoList={photoList} />

                    <span id="wedding_video" >
                        <VideoSkelet videos={videos} />
                    </span>


                    <div className="row mt-5 pt-5 text-center">
                        <div className="col-12 col-lg-6 ">
                            <h2 className="mb-3">SVATEBNÍ FOTO</h2>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(3)}>
                                    PREMIUM
                                    <FontAwesomeIcon
                                        className="align-middle icon icon3"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <ul className="price-pack pp3 p-0">
                                    <li>Fotografování bez časového omezení.</li>
                                    <li>Předsvatební fotografování</li>
                                    <li>Dvě luxusní fotoknihy dle vlastního výběru fotek</li>
                                    <li>Online privátní galerie fotek</li>
                                    <li>Dárkový dřevěný usb flash disk</li>
                                    <li>Zapůjčení Instax fotoaparátu</li>
                                    <li>Dodání fotografií do 72 hodin</li>
                                    <li>Doprava po ČR zdarma</li>
                                    <li>500+ fotografií</li>

                                    <b>CENA: 29 000Kč</b>
                                </ul>
                            </div>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(2)}>
                                    STANDARD
                                    <FontAwesomeIcon
                                        className="align-middle icon icon2"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <ul className="price-pack pp2 p-0">
                                    <li>Fotografování na 12 hodin</li>
                                    <li>Online privátní galerie fotek</li>
                                    <li>Dárkový dřevěný usb flash disk</li>
                                    <li>Zapůjčení Instax fotoaparátu</li>
                                    <li>Dodání fotografií do 4 týdnů</li>
                                    <li>Doprava po ČR zdarma</li>
                                    <li>Ochutnávka fotografií do 2 dnů od svatby</li>
                                    <li>350+ fotografií</li>

                                    <b>CENA: 16 500Kč</b>
                                </ul>
                            </div>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(1)}>
                                    MINI
                                    <FontAwesomeIcon
                                        className="align-middle icon icon1"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <ul className="price-pack pp1 p-0">
                                    <li>Fotografování na 2 hodiny</li>
                                    <li>Obřad, skupinové a párové foto</li>
                                    <li>Online privátní galerie fotek</li>
                                    <li>Dodání fotografií do 4 týdnů</li>
                                    <li>Doprava po ČR zdarma</li>

                                    <b>CENA: 7 500Kč</b>
                                </ul>
                            </div>

                            <Link to="/kontakt">
                                <div className="btn btn-svatby rounded-0 border border-dark border-end-0 border-start-0 mt-3 mb-5">
                                    Napište mi
                                </div>
                            </Link>
                        </div>

                        <div className="col-12 col-lg-6">
                            <h2 className="mb-3">SVATEBNÍ VIDEO</h2>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(4)}>
                                    PREMIUM
                                    <FontAwesomeIcon
                                        className="align-middle icon icon4"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <ul className="price-pack pp4  p-0">
                                    <li>Natáčení bez časového omezení</li>
                                    <li>Dva kameramani</li>
                                    <li>Svatební videoklip s licencovanou hudbou</li>
                                    <li>Dárkový dřevěný usb flash disk</li>
                                    <li>Zapůjčení Instax fotoaparátu</li>
                                    <li>Doprava po ČR zdarma</li>
                                    <li>Záběry z dronu</li>


                                    <b>CENA: 35 000Kč</b>
                                </ul>
                            </div>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(5)}>
                                    STANDARD
                                    <FontAwesomeIcon
                                        className="align-middle icon icon5"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <ul className="price-pack pp5  p-0">
                                    <li>Natáčení 12 hodin</li>
                                    <li>Svatební videoklip s licencovanou hudbou</li>
                                    <li>Dárkový dřevěný usb flash disk</li>
                                    <li>Doprava po ČR zdarma</li>
                                    <li>Záběry z dronu</li>


                                    <b>CENA: 24 000Kč</b>
                                </ul>
                            </div>
                            <Link to="/kontakt">
                                <div className="btn btn-svatby rounded-0 border border-dark border-end-0 border-start-0 mt-3 mb-5">
                                    Napište mi
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center  mb-5 col-12 col-md-7 mx-auto">
                        <img src={envelope} alt="dárkové balení" className="w-100" />
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default SvatbyEl;
