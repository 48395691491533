import React from "react";
import SvatbyEl from "../components/svatbyEl";

const SvatbyVideo = () => {
    return (
        <SvatbyEl />
    )
};

export default SvatbyVideo;
